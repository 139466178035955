import React from 'react'
import Image from '../components/Image'
import { Four, One, Row, RowGroup, Three, Two } from '../components/Grid'
import SEO from '../components/SEO'
import { H2, H4, Section, SEOH1, SmallText } from '../components/Style'
import Button from '../components/Button'
import styled from 'react-emotion'
import Link from '../components/Link'
import Layout from '../components/Layout'

const breadcrumbs = `{
  "@context": "http://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [{
    "@type": "ListItem",
    "position": 1,
    "item": {
      "@id": "https://500tech.com/consulting",
      "name": "Consulting"
    }
  }]
}`

export default () => {
  return (
    <Layout>
      <SEO
        path="/consulting"
        title="Front-end consulting services by 500Tech"
        description="Maintain, scale, and understand the tech behind your products by partnering with an experienced development consulting firm."
        ld={breadcrumbs}
      />

      <Section>
        <Row>
          <SEOH1>On-site consulting</SEOH1>

          <Three>
            We build complex, intuitive, and high-performing front ends. As a
            result, we have the required experience to consult development teams
            and help them maintain, scale, and understand the tech behind their
            products. From build optimization and architecture to migration and
            performance, we help organizations make informed technical decisions
            and guide them through project implementation and maintenance.
          </Three>
        </Row>

        <RowGroup>
          <H2>Choosing a technology stack</H2>
          <Row>
            <One>
              <SmallText>
                <ul>
                  <li>Angular, React, or Vue?</li>
                  <li>setState, Redux, or MobX?</li>
                  <li>JavaScript, TypeScript, or Flow?</li>
                </ul>
              </SmallText>
            </One>

            <One>
              <SmallText>
                <ul>
                  <li>React Native or NativeScript?</li>
                  <li>Mobile Web, Webview, Hybrid, or Native?</li>
                  <li>Ionic or Cordova?</li>
                </ul>
              </SmallText>
            </One>

            <One>
              <SmallText>
                <ul>
                  <li>Formik or ReduxForm?</li>
                  <li>SCSS, CSS Modules, or Styled Components?</li>
                </ul>
              </SmallText>
            </One>

            <One>
              <SmallText>
                <ul>
                  <li>Webpack or Rollup?</li>
                  <li>Server Side Rendering or Static Pages?</li>
                  <li>NodeJS, Netlify, or AWS?</li>
                </ul>
              </SmallText>
            </One>
          </Row>

          <Row>
            <Four>
              <Image path="consulting/stacks.jpg" />
            </Four>
          </Row>

          <Row>
            <Three>
              <p>
                Use our expertise to make educated and well-weighted decisions
                for your next project. Ask us the hardest questions. Your next
                few years can depend on the right answers.
              </p>
            </Three>
          </Row>
        </RowGroup>
      </Section>

      <Section>
        <RowGroup>
          <Row>
            <Three>
              <H2>Framework migration and code modernization</H2>
              We helped many clients transition their legacy codebases to modern
              frameworks by defining the best strategy, estimating timelines,
              and efficiently executing the move without regressions or
              performance penalties while also passing the knowledge to
              engineers.
            </Three>
          </Row>

          <SmallText>
            <Row>
              <One>
                <H4>Frameworks</H4>
                <ul>
                  <li>AngularJS ⟶ React or Angular</li>
                  <li>Backbone ⟶ React or Angular</li>
                  <li>Ionic ⟶ React Native</li>
                  <li>ngRx ⟶ Redux</li>
                  <li>Angular ⟷ React</li>
                </ul>
              </One>

              <One>
                <H4>Languages</H4>
                <ul>
                  <li>ES5 ⟶ ES2015+</li>
                  <li>JavaScript ⟶ TypeScript or Flow</li>
                  <li>CoffeeScript ⟶ JavaScript</li>
                  <li>Flow ⟷ TypeScript</li>
                  <li>HTML ⟶ JSX</li>
                </ul>
              </One>

              <One>
                <H4>Infrastructure</H4>
                <ul>
                  <li>Gulp or Grunt ⟶ Webpack</li>
                  <li>Karma or Mocha ⟶ Jest</li>
                  <li>Monorepo ⟷ Many repos</li>
                  <li>NodeJS ⟶ AWS</li>
                  <li>Bower ⟶ NPM</li>
                </ul>
              </One>

              <One>
                <H4>Random</H4>
                <ul>
                  <li>SCSS ⟶ CSS Modules</li>
                  <li>Promises ⟶ async/await</li>
                  <li>VS Code ⟶ WebStorm</li>
                  <li>Underscore ⟶ Lodash</li>
                  <li>require ⟶ import</li>
                </ul>
              </One>
            </Row>
          </SmallText>
        </RowGroup>
      </Section>

      <Section>
        <RowGroup>
          <Row>
            <Two>
              <Image path="consulting/flamecharts.jpg" innerShadow />
            </Two>
            <Two>
              <Image path="consulting/lighthouse.jpg" innerShadow />
            </Two>
          </Row>
          <Row>
            <One>
              <H2>SSR</H2>
              We help choosing the right strategy to make sure search engines
              crawl and index relevant parts of your application. We've done
              server-side rendering, pre-rendering, static websites, and
              caching.
            </One>
            <Two>
              <H2>Performance optimization</H2>
              Consumer facing applications often require to fast page loads and
              smooth performance regardless of how old are their users' devices
              and how slow is their internet connection. We can help your team
              improve required metrics from time-to-first-byte to
              time-to-interactive, and help them understand common tools such as
              flame charts.
            </Two>
            <One>
              <H2>Monitoring metrics</H2>
              Good performance can regress and degrade over time if not
              carefully monitored and analyzed. We can help define the right
              metrics and set up performance monitoring tools.
            </One>
          </Row>
        </RowGroup>
      </Section>

      <Row>
        <Three>
          <div style={{ marginBottom: 20 }}>
            <Image path="jobs/2.jpg" />
          </div>
          <H2>Architecture and infrastructure</H2>
          <p>
            We've worked with many types of production applications with various
            architecture approaches: MVC, Flux, micro front ends, embeddable
            widgets, and more. We can discuss potential pitfalls.
          </p>
        </Three>
        <One>
          <H2>Technical mentorship and code audit</H2>
          Make sure your team follows the best practices, makes the right
          technical decisions, and doesn't accumulate too much technical debt.
          We come to assess code quality, advise on best practices, and improve
          your productivity.
        </One>
      </Row>

      <Row>
        <Three>
          <p>
            Let's meet in your office or over coffee and talk about your front
            end, technology stack, and current practices. No sales people
            involved.
          </p>
          <ButtonLink to="/contact">Schedule a free consultation</ButtonLink>
        </Three>
      </Row>
    </Layout>
  )
}

const ButtonLink = styled(Button.withComponent(Link))`
  color: white !important;
  border-bottom: none !important;
`
